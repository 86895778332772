import { Link, withPrefix } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Article from '../templates/Article'

const NotFound = props => {
  const [counter, setCounter] = useState(10)

  useEffect(() => {
    const timer = setTimeout(() => setCounter(counter - 1), 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [counter])

  useEffect(() => {
    if (counter <= 0) location.replace(withPrefix('/'))
  }, [counter])

  const pageTitle = 'Page Not Found'
  return (
    <Article pathName={props.location.pathname} pageTitle={pageTitle}>
      <StyledArticle>
        <StyledGrid>
          <StyledRobot>
            <img src={withPrefix('/ui/404-robot.svg')} alt="404 robot" />
          </StyledRobot>
          <div>
            <p>
              The page you're looking for wasn't found.
              <br />
              Let's get you...
            </p>
            <StyledTimer>
              <p>Back home in {counter}</p>
            </StyledTimer>
            <p>
              <Link to="/">Home Page</Link>
            </p>
          </div>
        </StyledGrid>
      </StyledArticle>
    </Article>
  )
}

NotFound.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

const StyledArticle = styled.article`
  margin: 0 auto;
  max-width: 75ch;
  overflow-y: hidden;

  a {
    color: var(--color-green-600);

    &:hover {
      color: hsl(168, 76%, 22%);
    }
  }
`

const StyledRobot = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;

  img {
    width: 300px;
  }
`

const StyledTimer = styled.div`
  border: 1px solid var(--color-gray-400);
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  width: 100%;
`

const StyledGrid = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  text-align: center;

  @media screen and (min-width: 64em) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    column-gap: 1.5rem;

    div:nth-child(1) {
      grid-column: 2;
    }
    div:nth-child(2) {
      grid-column: 1;
      grid-row: 1;
    }
  }
`

export default NotFound
